import React, { Component } from 'react';
import { graphql, Link } from 'gatsby';
import { navigate } from '@reach/router';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import ReactHtmlParser from 'react-html-parser';
import ComponentStyles from '../brand-styles/pages/recipe-collections.styles';

import PagesHero from '../components/PagesHero';
import SubHeading from '../components/SubHeading';
import Heading from '../components/Heading';
import { breakpoints, colors } from '../style-utilities/variables';
import RecipeSearchWidget from '../components/RecipeSearchWidget';
import RecipeCollectionCard from '../components/RecipeCollectionCard';
import StyledLink from '../components/StyledLink';

const CollectionSection = styled.div`
  padding: 0 4rem;
  .collection-cards {
    display: -ms-grid;
    display: grid;
    grid-template-columns: 1fr 1fr;
    -ms-grid-columns: 1fr 1fr;
    @media (min-width: ${breakpoints.tablet}) {
      margin: 5rem auto;
    }
    @media (max-width: 767px) {
      margin: 30px 15px !important;
    }
  }
  .collection-content {
    -ms-grid-column: 2;
  }
  #collection-images,
  #collection-content {
    text-align: center;
  }
  #collection-images {
    width: 100%;
    @media (-ms-high-contrast: none) {
      width: auto;
    }
    @media (max-width: tablet) {
      min-width: auto !important;
    }
    /* min-width: 425px; */
    @media (min-width: 1100px) {
      min-width: 600px;
    }
    img {
      height: 350px;
      width: 450px;
    }
    a {
      @media (max-width: tablet) {
        min-width: auto !important;
      }
      /* min-width: 425px; */
      @media (min-width: 1100px) {
        min-width: 600px;
      }
      @media (-ms-high-contrast: none) {
        display: inline-block;
      }
      div {
        @media (-ms-high-contrast: none) {
          min-height: 250px;
        }
      }
      @media (-ms-high-contrast: none) {
        .top-left {
          width: 49.5%;
          display: inline-block;
          margin-right: 0.5%;
        }
        .top-right {
          width: 49.5%;
          display: inline-block;
          margin-left: 0.5%;
        }
        .bottom {
        }
      }
    }
  }
  #collection-content {
    padding: 5em 0 5em 3em;
  }
  @media (min-width: 768px) {
    #collection-content {
      text-align: left;
    }
  }
  @media (min-width: 1300px) {
    #collection-content {
      padding: 5em;
    }
  }
`;

const Div = styled.div`
  height: auto;
  .search {
    display: none;
    position: absolute;
    margin: 3rem;
    z-index: 3;
    @media (min-width: ${breakpoints.tablet}) {
      display: block;
    }
  }

  @media (max-width: ${breakpoints.tablet}) {
    .top-button-section {
      margin-top: auto;
    }
    ${CollectionSection} {
      margin: 60px auto 30px;
      padding: 0;
      .collection-cards {
        display: block;
        margin: 30px auto;
      }
      #collection-images {
        img {
          width: 100%;
        }
      }
      #collection-content {
        padding: 20px;
      }
    }
  }

  ${ComponentStyles}
`;

class RecipeCollections extends Component {
  state = {
    searchValue: '',
    nestedRecipesArray: []
  };
  // Pulling data from CMS
  recipeCollections = this.props.data.allWordpressWpRecipeCollections.edges;
  allRecipes = this.props.data.allWordpressWpRecipes.edges;

  /**
   * METHOD: Render the 3 picked Recipe Collections from CMS data
   */
  renderCollections = () => {
    return this.recipeCollections.map(
      ({ node: { title, content, slug, acf } }) => {
        // Getting recipes for Each Collection
        const collectionRecipes = this.allRecipes.filter(item =>
          acf.recipes.some(
            e => e.recipe.wordpress_id === item.node.wordpress_id
          )
        );
        return (
          <div className="collection-cards" key={title}>
            <div id="collection-images">
              <RecipeCollectionCard
                recipes={collectionRecipes}
                slug={slug}
                // className="collection-card"
              />
            </div>
            <div id="collection-content" className="collection-content">
              <h2>{ReactHtmlParser(title)}</h2>
              <div>{ReactHtmlParser(content)}</div>
              <StyledLink link={`/recipe-collections/${slug}`}>
                View this Collection
              </StyledLink>
            </div>
          </div>
        );
      }
    );
  };
  /**
   * MEHOD: Getting values from input
   */
  onChangeHandler = e => {
    const value = e.target.value;
    this.setState({ searchValue: value });
  };
  /**
   * METHOD: Submit form search for recipes
   */
  submitHandler = e => {
    e.preventDefault();
    this.setState(() => {
      navigate(`/recipe-search?includes=${this.state.searchValue}`);
    });
  };

  render() {
    const recipeSearchBackground = this.props.data.wordpressPage.acf
      .search_widget_image.source_url;
    return (
      <Div>
        <Helmet>
          <title>
            {this.props.data.wordpressPage.yoast_head_json.title}
          </title>
          <meta
            name="description"
            content={
              this.props.data.wordpressPage.yoast_head_json.description
            }
          />
        </Helmet>
        <div className="search">
          <StyledLink link="/recipe-search">Search Recipes</StyledLink>
        </div>
        <PagesHero
          className="hero-section"
          image={this.props.data.wordpressPage.acf.hero_image.source_url}
        >
          {this.props.siteTitle === 'mahatma' ? (
            <div className="smokeBlur" />
          ) : (
            ''
          )}
          <div className="InnerBox">
            <div className="title">
              <h1>
                {ReactHtmlParser(
                  this.props.data.wordpressPage.acf.recipe_collection_header
                )}
              </h1>
            </div>
          </div>
        </PagesHero>
        <CollectionSection>{this.renderCollections()}</CollectionSection>
        <RecipeSearchWidget
          background={recipeSearchBackground}
          clicked={this.submitHandler}
          changeHandler={this.onChangeHandler}
        />
      </Div>
    );
  }
}

export default RecipeCollections;

export const query = graphql`
  query {
    wordpressPage(slug: { eq: "recipe-collections" }) {
      yoast_head_json {
        title
        description
      }
      acf {
        recipe_collection_header
        hero_image {
          source_url
        }
        search_widget_image {
          source_url
        }
      }
    }
    allWordpressWpRecipeCollections {
      edges {
        node {
          title
          slug
          content
          acf {
            recipes {
              recipe {
                post_name
                wordpress_id
              }
            }
          }
        }
      }
    }
    allWordpressWpRecipes {
      edges {
        node {
          wordpress_id
          slug
          acf {
            thumbnail {
              source_url
            }
          }
        }
      }
    }
  }
`;
