import { css } from 'styled-components';
import { colors, breakpoints, fonts } from '../../style-utilities/variables';

export default css`
  .collection-content {
    h2 {
      font-family: ${fonts.primary};
      color: ${colors.primary};
    }
  }

  span.count.badge {
    background: ${colors.primary};
  }
`;
